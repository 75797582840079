import React from "react";
import {
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
    WidgetProps,
} from "@rjsf/utils";
import {BaseInputTemplate} from "../Template/BaseInput";

export function TextWidget<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: WidgetProps<T, S, F>) {
    return <BaseInputTemplate {...props} />;
}
