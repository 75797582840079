import Axios from "axios";

const axios = Axios.create({
  baseURL: `${
    process.env.REACT_APP_API_URL || "https://api.socijalnakarta.com"
  }/api/v1/`,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : undefined,
  },
});

export default axios;
