import { FC } from "react";
import Logo from "components/Logo";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { mapIconsToSidebarItemUrl } from "utils/helpers";

export interface AdminMenu {
  label: string;
  url: string;
  action_name?: string;
  children?: AdminMenu[];
}

const menu: AdminMenu[] = [
  {
    label: "Kontrolna ploca",
    url: "/",
  },
  {
    label: "Kategorije atributa",
    url: "/attribute-categories",
    action_name: "attribute-definition-categories.index",
  },
  {
    label: "Definicije atributa",
    url: "/attribute-definitions",
    action_name: "attribute-definitions.index",
  },
  {
    label: "Atributi",
    url: "/new-attributes",
    action_name: "new-attributes.index",
  },
  {
    label: "Entiteti",
    url: "/entities",
    action_name: "entities.index",
  },
  {
    label: "Ankete",
    url: "/surveys",
  },
  {
    label: "Nova Anketa",
    url: "/surveys/new",
  },
  {
    label: "Tipovi entiteta",
    url: "/entity-types",
    action_name: "entity-types.index",
  },
  {
    label: "Korisnici",
    url: "/users",
    action_name: "users.index",
  },
  {
    label: "Upravljanje ulogama",
    url: "/roles",
    action_name: "roles.index",
  },
  {
    label: "Privilegije",
    url: "/permissions",
    action_name: "permissions.index",
  },
];

const AdminSidebar: FC = () => {
  const { localUser } = useAuth();
  return (
    <>
      <div className="top">
        <Logo />
      </div>
      <div className="dashboard-menus">
        <ul className="dashboard-menu">
          {menu
            .filter(
              (menuItem) =>
                localUser.roles.includes("SuperAdmin") ||
                !menuItem.action_name ||
                localUser.permissions
                  .map((p) => p.action_name)
                  .includes(menuItem.action_name)
            )
            .map((menuItem) => (
              <NavLink key={menuItem.url} to={menuItem.url} className="">
                <span>
                  {mapIconsToSidebarItemUrl(menuItem.url)} {menuItem.label}
                </span>
                {menuItem.children &&
                  menuItem.children.map((subMenuItem) => (
                    <NavLink
                      key={menuItem.url + subMenuItem.url}
                      to={menuItem.url + subMenuItem.url}
                    >
                      <span>{subMenuItem.label}</span>
                    </NavLink>
                  ))}
              </NavLink>
            ))}
        </ul>
      </div>
    </>
  );
};

export default AdminSidebar;