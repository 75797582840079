import { FaEnvelope, FaLock } from "react-icons/fa";

import { Form, Formik, useField } from "formik";
import { validateRequired } from "const/messages";
import { Button, Checkbox, Columns, Container, Input } from "components/Bulma";
import { useAuth } from "hooks/auth";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Layout from "components/Layout";

const initialValues = {
  email: "",
  password: "",
  remember: false,
};

export default function LoginPage() {
  const { localUser, login } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async ({
    email,
    password,
    remember,
  }: typeof initialValues) => {
    try {
      await login({ email, password, remember });

      navigate("/");
    } catch {
      setError("Podaci nisu ispravni.");
    }
  };

  if (localUser) {
    return <Navigate to={`/`} />;
  }

  return (
    <Layout guest>
      <section className="hero is-dark is-fullheight">
        <div className="hero-body">
          <Container>
            <Columns centered>
              <div className="column is-5-tablet is-4-desktop">
                {Boolean(error) && (
                  <div className="notification is-danger">{error}</div>
                )}

                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validate={(values) => {
                    const errors: any = {};

                    if (!values.email) errors.email = validateRequired;
                    if (!values.password) errors.password = validateRequired;

                    return errors;
                  }}
                >
                  {(props) => (
                    <Form className="login-window box">
                      <h1 className="is-size-3 has-text-centered has-text-dark mb-4">
                        Prijava
                      </h1>

                      <Input
                        type="email"
                        name="email"
                        label="Email"
                        useField={useField}
                        rightIcon={<FaEnvelope />}
                      />
                      <Input
                        type="password"
                        name="password"
                        label="Lozinka"
                        useField={useField}
                        rightIcon={<FaLock />}
                      />

                      <Checkbox name="remember" useField={useField}>
                        {" "}
                        Ostani prijavljen ?
                      </Checkbox>

                      <Button
                        className="is-fullwidth"
                        loading={props.isSubmitting}
                        variant="info"
                        size="medium"
                      >
                        Prijavi se
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Columns>
          </Container>
        </div>
      </section>
    </Layout>
  );
}
