import React, { FC, InputHTMLAttributes } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
	variant?: 'normal' | 'medium' | 'large';
	defaultStyle?: boolean;
	useField?: any;
}

export const Checkbox: FC<CheckboxProps> = ({
	className,
	id,
	name,
	variant: size = 'large',
	useField = (name: string) => [{ name }, {}],
	children,
	defaultStyle,
	...props
}) => {
	const classes = defaultStyle ? [] : ['is-checkradio'];
	const [field, meta] = useField(String(name));

	if (className) classes.push(className);

	if (!id) id = `checkbox-input-${name}`;

	if (size) classes.push(`is-${size}`);

	const hasError = Boolean(meta.error);

	if (hasError) classes.push('is-danger');

	field.checked = !!field.value;

	return (
		<div className='field checkbox-field'>
			<div className='checkbox-container'>
				<label htmlFor={id}>
					<input
						type='checkbox'
						name={name}
						{...props}
						{...field}
						className={classes.join(' ')}
						id={id}
					/>
					<span className='checkbox-checkmark'></span>
					<span className='checkbox-label'>{children}</span>
				</label>
			</div>
			{hasError && <span className='help is-danger'>{meta.error}</span>}
		</div>
	);
};
