import useSWR from "swr";
import axios from "lib/axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IUser } from "interface/IUser";

export const useAuth = ({ middleware, redirectIfAuthenticated }: any = {}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    data: user,
    error,
    mutate,
  } = useSWR<IUser>("user", () => validateUser());

  const validateUser = async () => {
    return axios
      .get("user")
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data));

        return res.data;
      })
      .catch((error) => {
        if (error.response.status !== 409) throw error;

        navigate("/verify-email");
      });
  };

  const register = async ({ setErrors, ...props }: any) => {
    setErrors([]);

    axios
      .post("register", props)
      .then(() => mutate())
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  const login = async ({ email = "", password = "", remember = false }) => {
    return axios.post("login", { email, password, remember }).then((res) => {
      console.log(res.data);

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user));

      mutate();
    });
  };

  const forgotPassword = async ({ setErrors, setStatus, email }: any) => {
    setErrors([]);
    setStatus(null);

    axios
      .post("forgot-password", { email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  const resetPassword = async ({ setErrors, setStatus, ...props }: any) => {
    setErrors([]);
    setStatus(null);

    axios
      .post("reset-password", {
        token: searchParams.get("token"),
        ...props,
      })
      .then((response) =>
        navigate("/login?reset=" + btoa(response.data.status))
      )
      .catch((error) => {
        if (error.response.status !== 422) throw error;

        setErrors(error.response.data.errors);
      });
  };

  const resendEmailVerification = ({ setStatus }: any) => {
    axios
      .post("email/verification-notification")
      .then((response) => setStatus(response.data.status));
  };

  const logout = async () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");

    if (!error) {
      await axios.post("logout").then(() => {
        return mutate();
      });
    }

    window.location.pathname = "/login";
  };

  useEffect(() => {
    if (middleware === "guest" && redirectIfAuthenticated && user)
      navigate(redirectIfAuthenticated);
    if (window.location.pathname === "/verify-email" && user?.email_verified_at)
      navigate(redirectIfAuthenticated);
    if (middleware === "auth" && error) logout();
  }, [user, error]);

  const storageUser = localStorage.getItem("user");
  const localUser: IUser = storageUser ? JSON.parse(storageUser) : undefined;

  return {
    localUser,
    user,
    register,
    login,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
  };
};
