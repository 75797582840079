import React, { CSSProperties } from "react";
import {
    ArrayFieldTemplateItemType,
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
} from "@rjsf/utils";
import {DRAFT_KEY} from "../../routes/Survey";

/** The `ArrayFieldItemTemplate` component is the template used to render an items of an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export function ArrayFieldItemTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: ArrayFieldTemplateItemType<T, S, F>) {
    const {
        children,
        className,
        disabled,
        hasToolbar,
        // hasMoveDown,
        // hasMoveUp,
        hasRemove,
        index,
        onDropIndexClick,
        onReorderClick,
        readonly,
        registry,
        uiSchema,
    } = props;
    const hasMoveDown = false
    const hasMoveUp = false
    const { MoveDownButton, MoveUpButton, RemoveButton } =
        registry.templates.ButtonTemplates;
    const btnStyle: CSSProperties = {
        // flex: 1,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: "bold",
    };
    const deleteButtonStyle: CSSProperties = {
        ...btnStyle, right: 0, top: 0, position: "absolute"
    }

    // beginning of criminal
    interface KucnaListaItem {
        ime: string
        prezime: string
    }
    const fullNames = localStorage.getItem(DRAFT_KEY)
        ? JSON.parse(localStorage.getItem(DRAFT_KEY) as string)['kucna-lista'].map((member: KucnaListaItem) => `${member.ime || ''} ${member.prezime || ''}`)
        : []
    // end of criminal

    return (
        <div className={className}>
            <div className={hasToolbar ? "col-xs-12" : "col-xs-12"}>
                <span className='label label-info' style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}>Ispitanik: {fullNames[index] || ''}</span>
                {children}
                <RemoveButton
                    style={deleteButtonStyle}
                    disabled={disabled || readonly}
                    onClick={onDropIndexClick(index)}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            </div>
            {hasToolbar && (
                <div className="col-xs-1 array-item-toolbox" hidden={true}>
                    <div
                        className="btn-group"
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        {(hasMoveUp || hasMoveDown) && (
                            <MoveUpButton
                                style={btnStyle}
                                disabled={disabled || readonly || !hasMoveUp}
                                onClick={onReorderClick(index, index - 1)}
                                uiSchema={uiSchema}
                                registry={registry}
                            />
                        )}
                        {(hasMoveUp || hasMoveDown) && (
                            <MoveDownButton
                                style={btnStyle}
                                disabled={disabled || readonly || !hasMoveDown}
                                onClick={onReorderClick(index, index + 1)}
                                uiSchema={uiSchema}
                                registry={registry}
                            />
                        )}
                        {hasRemove && (
                            <RemoveButton
                                style={btnStyle}
                                disabled={disabled || readonly}
                                onClick={onDropIndexClick(index)}
                                uiSchema={uiSchema}
                                registry={registry}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
