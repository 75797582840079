import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardPage from "routes/DashboardPage";
import LoginPage from "routes/LoginPage";
import {AttributeCategories, AttributeCategory} from "routes/AttributeCategory";
import ErrorPage from "./error-page";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { AttributeDefinitions, AttributeDefinition } from "./routes/AttributeDefinition";
import { Attributes, Attribute } from "./routes/Attribute";
import { Entities, Entity } from "./routes/Entity";
import { UserListing, UserEdit } from "./routes/User";
import { PermissionListing, PermissionEdit } from "./routes/Permission";
import { EntityTypes, EntityType } from "./routes/EntityTypes";
import {RoleEdit, RoleListing} from "./routes/Role";
import {Survey, SurveyIndex} from "./routes/Survey";

import 'swiper/css';

const router = createBrowserRouter([
  {
    index: true,
    path: '/',
    element: <DashboardPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/attribute-categories',
    element: <AttributeCategories />,
    children: [
    ]
  },
  {
    path: '/attribute-categories/:id',
    element: <AttributeCategory />,
  },
  {
    element: <AttributeDefinitions />,
    path: '/attribute-definitions',
  },
  {
    element: <AttributeDefinition />,
    path: '/attribute-definitions/:id',
  },
  {
    element: <Attributes />,
    path: '/new-attributes',
  },
  {
    element: <Attribute />,
    path: '/new-attributes/:id',
  },
  {
    element: <Entities />,
    path: '/entities',
  },
  {
    element: <Entity />,
    path: '/entities/:id',
  },
  {
    element: <UserListing />,
    path: '/users',
  },
  {
    element: <UserEdit />,
    path: '/users/:id',
  },
  {
    element: <SurveyIndex />,
    path: '/surveys',
  },
  {
    element: <Survey />,
    path: '/surveys/new',
  },
  {
    element: <PermissionListing />,
    path: '/permissions',
  },
  {
    element: <PermissionEdit />,
    path: '/permissions/:id',
  },
  {
    element: <EntityTypes />,
    path: "/entity-types",
  },
  {
    element: <EntityType />,
    path: "/entity-types/:id",
  },
  {
    element: <RoleListing />,
    path: "/roles",
  },
  {
    element: <RoleEdit />,
    path: "/roles/:id",
  },
]);

const elem = document.getElementById('root');

if (elem) {
  ReactDOM.createRoot(elem).render(
      <RouterProvider router={router} />
  );
}

serviceWorkerRegistration.register();
