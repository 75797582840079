import React, { FC, HtmlHTMLAttributes } from 'react';

export const Block: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
	children,
	className,
	...other
}) => {
	return (
		<div className={className ? `${className} block` : 'block'} {...other}>
			{children}
		</div>
	);
};
