import React, { ButtonHTMLAttributes } from 'react';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: any;
	variant?: 'primary' | string;
	outlined?: boolean;
	rounded?: boolean;
	size?: string;
	component?: string;
	href?: string;
	target?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, IButton>(
	(props, ref) => {
		const {
			component = 'button',
			loading,
			className,
			variant: is = 'primary',
			outlined = false,
			rounded,
			size,
			...inputProps
		} = props;
		const classes = [`button is-${is}`];

		if (className) classes.push(className);
		if (loading) classes.push('is-loading');
		if (outlined) classes.push('is-outlined');
		if (rounded) classes.push('is-rounded');
		if (size) classes.push(`is-${size}`);

		return React.createElement(
			component,
			{ ...inputProps, className: classes.join(' '), ref },
			props.children,
		);
	},
);

Button.displayName = 'Button';
