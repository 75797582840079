import React, { FC, HTMLAttributes } from 'react';

export const Container: FC<HTMLAttributes<HTMLDivElement>> = ({
	children,
	...other
}) => (
	<div {...other} className='container'>
		{children}
	</div>
);
