import { MultiStep } from "../components/MultiStep";
import Layout from "../components/Layout";
import {
  RJSFSchema,
  UiSchema,
  RegistryWidgetsType,
  RegistryFieldsType,
} from "@rjsf/utils";
import Form, { FormProps, IChangeEvent } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import axios from "../lib/axios";
import { useRef, useState } from "react";
import { ProgressBar } from "react-loader-spinner";
import { ArrayField } from "../components/Field/Array";
import { SchemaField } from "../components/Field/Schema";
import { StringField } from "../components/Field/String";
import { ObjectField } from "../components/Field/Object";
import { TextWidget } from "../components/Widget/Text";
import { FieldTemplate } from "../components/Template/Field";
import { NavButton, Step } from "../components/MultiStep/interfaces";

const fields: RegistryFieldsType = {
  ArrayField,
  ObjectField,
  SchemaField,
  StringField,
};

const widgets: RegistryWidgetsType = {
  TextWidget: TextWidget,
};

const SurveyUiSchema: UiSchema = {
  "ui:submitButtonOptions": {
    norender: true,
  },
};

const ArraySurveyUiSchema: UiSchema = {
  ...SurveyUiSchema,
  "ui:FieldTemplate": FieldTemplate,
};

enum LoadStatus {
  init,
  progress,
  done,
}

export const DRAFT_KEY = "draft_survey";

const SurveyIndex = () => (
  <Layout>
    <pre>In progress...</pre>
  </Layout>
);

const Survey = () => {
  const loadingStatus = useRef<LoadStatus>(LoadStatus.init);
  const [steps, setSteps] = useState<Step[]>([]);
  // @ts-ignore
  let draft: Record<string, any> = localStorage.getItem(DRAFT_KEY) ? JSON.parse(localStorage.getItem(DRAFT_KEY)) : {}


  const [formData, setFormData] = useState<Record<string, any>>(draft);

  const [currentStepTitle, setCurrentStepTitle] = useState<string>("");

  const submitHandler = () => {
    const payload: Record<string, Record<string, any> | Record<string, any>[]> =
      JSON.parse(localStorage.getItem(DRAFT_KEY) as string);

    let members: Record<string, any>[] = [];
    let household: Record<string, any> = {};

    for (const attr in payload) {
      if (Array.isArray(payload[attr])) {
        payload[attr].forEach((item: Record<string, any>, key: number) => {
          if (!members[key]) {
            members[key] = {
              attributes: {},
            };
          }
          members[key].attributes = { ...members[key].attributes, ...item };
        });
        continue;
      }
      household.attributes = { ...household.attributes, ...payload[attr] };
    }
    members = members.map((member) => ({ ...member, entity_type_id: 2 }));
    household = { ...household, entity_type_id: 1 };

    axios.post("survey-schemas", { household, members }).then((response) => {
      alert("Done");
    });
  };

  const changeHandler =
    (key: string) =>
    (e: IChangeEvent<any, RJSFSchema, any>): void => {
      const data = { ...formData };
      data[key] = e.formData;

      if (Array.isArray(e.formData)) {
        const arrayLenght = e.formData.length;

        steps.forEach((step: Step) => {
          if (step.component.props.schema.type === "array") {
            data[step.key] = data[step.key]
              ? data[step.key].slice(0, arrayLenght)
              : [];

            while (data[step.key].length !== arrayLenght) {
              data[step.key].push({});
            }
          }
        });
      }

      setFormData(data);
      localStorage.setItem(DRAFT_KEY, JSON.stringify(data));
      loadingStatus.current = LoadStatus.progress;
      // debugger
    };

  if (loadingStatus.current === LoadStatus.init) {
    loadingStatus.current = LoadStatus.progress;
    const getParams = (
      data: Record<string, RJSFSchema>,
      key: string,
      isArray: boolean = false,
      isLastElement: boolean = false
    ): FormProps => {
      let params: FormProps = {
        onChange: changeHandler(key),
        onSubmit: submitHandler,
        formData: formData[key],
        schema: data[key],
        validator,
        uiSchema: {
          "ui:submitButtonOptions": {
            norender: false,
          },
        },
      };
      if (!isLastElement) {
        params = isArray
          ? {
              ...params,
              uiSchema: { ...ArraySurveyUiSchema },
              fields: fields,
              widgets: widgets,
            }
          : {
              ...params,
              uiSchema: { ...SurveyUiSchema },
            };
      }

      return params;
    };

    axios
      .get("survey-schemas")
      .then(({ data }: { data: Record<string, RJSFSchema> }) => {
        localStorage.setItem("djes", JSON.stringify(data));
        const keys = Object.keys(data);
        const newSteps = [];
        for (const [key, value] of Object.entries(data)) {
          newSteps.push({
            key,
            // @ts-ignore
            title: value.items?.title ?? value.title,
            component: (
              <Form
                {...getParams(
                  data,
                  key,
                  data[key].type === "array",
                  keys.indexOf(key) === keys.length - 1
                )}
              />
            ),
          });
        }
        setSteps(newSteps);

        loadingStatus.current = LoadStatus.done;
      });
  }

  const nextButton: NavButton = {
    title: "Sljedeći korak",
  };

  const prevButton: NavButton = {
    title: "Prethodni korak",
  };

  return (
    <Layout>
      <h1 className="is-size-1 mb-5">{currentStepTitle}</h1>
      {steps.length > 0 ? (
        <MultiStep
          keepStepState={true}
          showTitles={true}
          activeStep={0}
          showNavigation={true}
          steps={steps}
          nextButton={nextButton}
          prevButton={prevButton}
          titleStateSetter={setCurrentStepTitle}
        />
      ) : (
        <ProgressBar
          height="80"
          width="100%"
          ariaLabel="progress-bar-loading"
          wrapperClass="progress-bar-wrapper"
          borderColor="#111"
          barColor="#787e96"
        />
      )}
    </Layout>
  );
};

export { Survey, SurveyIndex };
