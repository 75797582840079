import React, { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	rightIcon?: any;
	useField?: any;
	datepicker?: boolean;
	addons?: any;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(props, ref) => {
		const {
			useField = (name: string) => [{ name }, {}],
			label,
			rightIcon,
			placeholder,
			datepicker,
			addons,
			...inputProps
		} = props;
		const placeholderText = placeholder
			? placeholder
			: undefined;

		const [field, meta] = useField(String(props.name));

		const hasError = Boolean(meta.error && meta.touched);

		const InputElem = (
			<input
				className={hasError ? 'input is-danger' : 'input'}
				placeholder={placeholderText}
				{...inputProps}
				{...field}
				required={false}
				ref={ref}
			/>
		);

		let classes = addons ? 'field has-addons' : 'field';
		if (props.name) classes = `${classes} field-${props.name}`;
		// @ts-ignore
		if (inputProps?.value || field?.value) classes = `${classes} has-value`;

		if (inputProps.type === 'hidden') return InputElem;

		return (
			<div className={classes}>
				{Boolean(label) && (
					<label className='label'>
						{label}
					</label>
				)}
				<div
					className={`control${rightIcon ? ' has-icons-right' : ''}`}
				>
					{InputElem}
					{hasError && (
						<span className='help is-danger'>{meta.error}</span>
					)}
					{Boolean(rightIcon) && (
						<span className='icon is-medium is-right'>
							{rightIcon}
						</span>
					)}
				</div>
				{Boolean(addons) && <div className='control'>{addons}</div>}
			</div>
		);
	},
);

Input.displayName = 'Input';
