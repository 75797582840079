import { ReactElement } from "react";
import { FaHome, FaClipboardList, FaUsers, FaUserCog, FaQuestionCircle, FaPlusCircle, FaStream } from "react-icons/fa";

export const iconToUrlMapper: Record<string, ReactElement> = {
  "/": <FaHome />,
  "/attribute-categories": <FaClipboardList />,
  "/attribute-definitions": <FaClipboardList />,
  "/new-attributes": <FaClipboardList />,
  "/entities": <FaStream />,
  "/surveys": <FaQuestionCircle />,
  "/surveys/new": <FaPlusCircle />,
  "/entity-types": <FaStream />,
  "/users": <FaUsers />,
  "/roles": <FaUserCog />,
  "/permissions": <FaUserCog />,
};
