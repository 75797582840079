import {DefaultListing} from "../components/DefaultListing";
import {ChangeHandler, DefaultEdit} from "../components/DefaultEdit";
import {RJSFSchema} from "@rjsf/utils";
import { IChangeEvent } from '@rjsf/core'

export const EntityTypes = () => DefaultListing(['id', 'name', 'slug'], 'entity-types')

const strSlug = (input: string, separator: string = '-'): string => input.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, separator)

interface EntityTypeI {
    id: number
    name: string
    slug: string
    created_at: string
    updated_at: string
}
const changeHandler: ChangeHandler = (e: IChangeEvent<EntityTypeI, RJSFSchema, any>, test) => {
    if (e.formData && Object.keys(e.formData).length > 0) {
        e.formData.slug = strSlug(e.formData.name)
    }
}

export const EntityType = () => DefaultEdit({endpoint: 'entity-types', changeHandler})
