import React from "react";
import {
    getWidget,
    getUiOptions,
    optionsList,
    hasWidget,
    FieldProps,
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
} from "@rjsf/utils";

export function StringField<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: FieldProps<T, S, F>) {
    const {
        schema,
        name,
        uiSchema,
        idSchema,
        formData,
        required,
        disabled = false,
        readonly = false,
        autofocus = false,
        onChange,
        onBlur,
        onFocus,
        registry,
        rawErrors,
    } = props;
    const { title, format } = schema;
    const { widgets, formContext, schemaUtils } = registry;
    const enumOptions = schemaUtils.isSelect(schema)
        ? optionsList(schema)
        : undefined;
    let defaultWidget = enumOptions ? "select" : "text";
    if (format && hasWidget<T, S, F>(schema, format, widgets)) {
        defaultWidget = format;
    }
    const {
        widget = defaultWidget,
        placeholder = "",
        ...options
    } = getUiOptions<T, S, F>(uiSchema);
    const Widget = getWidget<T, S, F>(schema, widget, widgets);
    return (
        // @ts-ignore
        <Widget
            options={{ ...options, enumOptions }}
            schema={schema}
            uiSchema={uiSchema}
            id={idSchema.$id}
            label={title === undefined ? name : title}
            value={formData}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            required={required}
            disabled={disabled}
            readonly={readonly}
            formContext={formContext}
            autofocus={autofocus}
            registry={registry}
            placeholder={placeholder}
            rawErrors={rawErrors}
        />
    );
}

