import React, { FC, useEffect, useState } from "react";
import "style/main.scss";
import { Column, Columns } from "../Bulma";
import AdminSidebar from "components/Layout/AdminSidebar";
import AdminTopBar from "components/Layout/AdminTopBar";
import { useWindowSize } from "react-use";

type Props = {
  children?: React.ReactNode;
  guest?: boolean;
  fullWidth?: boolean;
  title?: string;
  customActions?: React.ReactNode;
};

const Layout: FC<Props> = ({
  children,
  guest,
  fullWidth,
  title,
  customActions,
}) => {
  const { width } = useWindowSize();
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    setSidebar(width > 900);
  }, [width]);

  const loading = false;

  if (guest) return <>{children}</>;

  return (
    <div className={(sidebar ? "sidebar" : "no-sidebar") + " dashboard"}>
      <AdminTopBar sidebar={sidebar} setSidebar={setSidebar} />
      <div className="dashboard-content">
        {/* {sidebar && ( */}
          <div className="dashboard-content-sidebar">
            <AdminSidebar />
          </div>
        {/* )} */}
        <div
          className={
            loading ? "dashboard-content-box loading" : "dashboard-content-box"
          }
        >
          {!loading && (
            <div className={`box${fullWidth ? " is-full-width" : ""}`}>
              <Columns>
                <Column>
                  <h1 className="title is-1">{title}</h1>
                </Column>

                {Boolean(customActions) && (
                  <Column size="narrow">{customActions}</Column>
                )}
              </Columns>

              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;