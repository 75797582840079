import { DefaultListing } from "../components/DefaultListing";
import {DefaultEdit} from "../components/DefaultEdit";

const UserListing = () => DefaultListing(
    ['id', 'name', 'email', 'created_at', 'updated_at'],
    'users'
)

const UserEdit = () => DefaultEdit({endpoint: 'users'})

export {
    UserListing,
    UserEdit,
}
