import React, { CSSProperties, FC } from 'react';

export type BreakPoint =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 'narrow'
	| 'auto'
	| 'narrow-touch'
	| 'narrow-desktop'
	| number;

interface ColumnProps {
	size?: BreakPoint | BreakPoint[] | number;
	mobile?: BreakPoint;
	xs?: BreakPoint;
	md?: BreakPoint;
	lg?: BreakPoint;
	xl?: BreakPoint;
	className?: string;
	children?: any;
	style?: CSSProperties;
}

export const Column: FC<ColumnProps> = (props) => {
	const { size: is, xs, md, lg, mobile, className, children, style } = props;
	const classes = ['column'];

	if (is) classes.push(`is-${is}`);
	if (mobile) classes.push(`is-${mobile}-mobile`);
	if (xs) classes.push(`is-${xs}-tablet`);
	if (md) classes.push(`is-${md}-desktop`);
	if (lg) classes.push(`is-${lg}-widescreen`);
	if (className) classes.push(className);

	return (
		<div className={classes.join(' ')} style={style || undefined}>
			{children}
		</div>
	);
};
