import React, { FC, HTMLAttributes } from 'react';

interface ColumnsProps extends HTMLAttributes<HTMLDivElement> {
	mobile?: boolean;
	variable?: boolean;
	multiline?: boolean;
	gapless?: boolean;
	vcentered?: boolean;
	centered?: boolean;
	gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 'multiline';
}

export const Columns: FC<ColumnsProps> = (props) => {
	const {
		mobile,
		variable,
		gap,
		className,
		children,
		multiline,
		gapless,
		vcentered,
		centered,
		...other
	} = props;

	const classes = ['columns'];

	if (mobile) classes.push('is-mobile');
	if (variable) classes.push(`is-variable is-${gap || 1}`);
	if (className) classes.push(className);
	if (multiline) classes.push('is-multiline');
	if (gapless) classes.push('is-gapless');
	if (centered) classes.push('is-centered');
	if (vcentered) classes.push('is-vcentered');

	return (
		<div className={classes.join(' ')} {...other}>
			{children}
		</div>
	);
};
