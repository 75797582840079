import Layout from "./Layout";
import {Navigate, useNavigate, useParams, useLocation} from "react-router-dom";
import {useAuth} from "../hooks/auth";
import {diff as deepDiff} from "deep-object-diff";
import validator from "@rjsf/validator-ajv8";
import {
    ErrorSchema,
    ObjectFieldTemplateProps,
    RegistryWidgetsType,
    RJSFSchema,
    UiSchema,
} from "@rjsf/utils";
import Form, {FormProps, IChangeEvent} from '@rjsf/core'
import axios from "../lib/axios";
import {ReactElement, useRef, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {Autosuggess} from './Autosuggest'
import {useToggle} from "react-use";
import flat, {unflatten} from "flat";
import flatten from "flat";
import {ProgressBar} from "react-loader-spinner";

const diff: (a: Record<string, any>, b: Record<string, any>) => object = (
    a, b
) => Object.keys(deepDiff(a, b)).reduce(
    (cumulated: Record<string, any> ,curent: string) => {
        cumulated[curent] = b[curent]
        return cumulated
    }, {}
)
enum Status {
    init, progress, done
}

interface EditState {
    schema: RJSFSchema
    uiSchema: UiSchema
    item: any,
    formData: any,
}

interface RefState {
    status: Status
    form: ReactElement | null
}

export type ChangeHandler = (e: IChangeEvent<any, RJSFSchema, any>, test: string | undefined) => void

interface EditParam {
    endpoint: string
    changeHandler?: ChangeHandler
}

export const DefaultEdit = (param: EditParam) => {
    const { endpoint, changeHandler } = param;
    const location = useLocation();
    const {localUser, logout} = useAuth();

    let draft: Record<string, Record<any, any>> = {}
    const draftStr: string|null = localStorage.getItem('draft')
    if (draftStr !== null) {
        draft = JSON.parse(draftStr)
    }

    const {id} = useParams();

    const [editState, setEditState] = useState<EditState>({
        schema: {},
        uiSchema: {},
        item: undefined,
        formData: draft[id as string] || undefined,
    })
    const [extraErrors, setExtraErrors] = useState<ErrorSchema | undefined>(undefined)

    const editStateR = useRef<RefState>({
        status: Status.init,
        form: null
    });

    const navigate = useNavigate();

    const schemaUrl = id === 'create' ? `schemas/${endpoint}/create` : `schemas/${endpoint}/update/${id}`
    const ajaxes: { call: () => Promise<any>, callback: (i: any) => object }[] = [
        {
            call: () => axios.get(schemaUrl),
            callback: ({data}: { data: [RJSFSchema, RJSFSchema]}) => {
                let [schema, uiSchema] = data

                // for (const djes in uiSchema) {
                //     const item = uiSchema[djes]
                //     if (item['ui:options']) {
                //         item['ui:widget'] = Autosuggess
                //     }
                // }

                return { schema, uiSchema }
            }
        }
    ]

    if (id !== 'create') {
        ajaxes.push({
            call: () => axios.get(`${endpoint}/${id}`),
            callback: (resp: { data: object }) => {
                return {item: resp.data, formData: resp.data}
            }
        })
    }

    if (editStateR.current.status === Status.init) {
        editStateR.current.status = Status.progress
        Promise.all(ajaxes.map(ajax => ajax.call())).then(responses => {
            const callbacks = ajaxes.map(ajax => ajax.callback);
            const objects = responses.map((response, key) => callbacks[key](response))

            setEditState(
                {...editState, ...objects.reduce((cumul, current) => ({...cumul, ...current}), {})}
            )
            editStateR.current.status = Status.done
        }).catch((reason: AxiosError) => {
            const {status, data} = reason.response as AxiosResponse<{message: string}>

            if (status === 401) {
                logout()
                return <Navigate to={`/login`} replace/>
            }

            if (status === 403) {
                alert(data.message)
            }
            // reason.response?.statusText === 'Forbidden' ? logout() : null
        })
    }

    if (id === undefined) {
        return <Navigate to={endpoint} replace/>
    }


    if (!localUser) {
        logout()
        return <Navigate to={`/login`} replace/>
    }

    const defaultChangeHandler = (e: IChangeEvent<any, RJSFSchema, any>, test: string | undefined): void => {
        if (changeHandler) {
            changeHandler(e, test)
        }

        draft[id] = e.formData

        localStorage.setItem('draft', JSON.stringify(draft))

        if (e.formData && Object.keys(e.formData).length > 0) {
            // setEditState({ ...editState, formData: e.formData })
        }
        // const vd = validator.validateFormData(e.formData, e.schema)

        // console.log(e.formData, e.schema, vd)

    }

    console.log('ajs')
    console.log(flat)
    console.log( unflatten)
    function handleSubmit(e: IChangeEvent<any, RJSFSchema, any>) {
        const catchCallback = (e: AxiosError) => {
            const {status, data} = e.response as AxiosResponse<{errors: ErrorSchema, message: string}>
            if (status === 403) {
                alert(data.message)
            }

            setExtraErrors(data.errors)
        }

        let formData: Record<string, any> = flatten(e.formData as Object)
        Object.keys(formData).forEach((item: string) => {
            if (formData[item] && typeof formData[item] === 'object' && Object.keys(formData[item]).length === 0) {
                delete formData[item]
            }
        })
        formData = unflatten(formData)

        if (id === 'create') {
            if (JSON.stringify(formData) === '{}') {
                return;
            }
            return axios.post(
                endpoint, formData
            ).then((response) => {
                delete draft.create
                localStorage.setItem('draft', JSON.stringify(draft))

                setEditState({...editState, item: response.data, formData: response.data})
                alert('success')
                navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/${response.data.id}`)
            }).catch(catchCallback)
        }

        if (
            [editState.item, editState.formData].includes(undefined)
            && JSON.stringify(diff(editState.item, formData)) === '{}'
        ) {
            return;
        }

        const payload = diff(editState.item, formData);
        if (Object.keys(payload).length === 0) {
            return alert('Nothing to update');
        }
        axios.patch(
            `${endpoint}/${id}`, payload
        ).then((response) => {
            delete draft.create
            localStorage.setItem('draft', JSON.stringify(draft))
            setEditState({ ...editState, item: response.data, formData: response.data })
            alert('success')
        }).catch(catchCallback)
    }

    const widgets: RegistryWidgetsType = {
        autoguess: Autosuggess
    }

    function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
        const id = `collapse-${Date.now()}`
        const [show, toggleShow] = useToggle(false)

        const noColapsStyle = props.title === '__children' ? {paddingLeft: '15px'} : {}

        console.log(props.idSchema.$id)
        return props.schema.collapsable ? (
            <fieldset className='card' key={props.idSchema.$id} style={{padding: '10px'}}>
                <div className='card-header'>
                    <button onClick={toggleShow} type="button" className="btn btn-lg btn-link">
                        {props.title}
                    </button>
                </div>
                <div className={`collapse${show ? ' show': ''}`} id={id}>
                    {props.description}
                    {props.properties.map((element) => (
                        <div className='property-wrapper'>{element.content}</div>
                    ))}
                </div>
            </fieldset>
        ) : (
            <fieldset key={props.idSchema.$id} style={noColapsStyle}>
                {props.title}
                {props.description}
                {props.properties.map((element) => (
                    element.content
                ))}
            </fieldset>
        );
    }

    return <Layout>
        {
        (editStateR.current.status === Status.done) ? <Form
            schema={editState.schema}
            uiSchema={editState.uiSchema}
            formData={editState.formData}
            onChange={defaultChangeHandler}
            onSubmit={handleSubmit}
            validator={validator}
            extraErrors={extraErrors}
            widgets={widgets}
            templates={{ObjectFieldTemplate}}
        /> : <div className="spinner-border" role="status">
            <ProgressBar
                height="80"
                width="100%"
                ariaLabel="progress-bar-loading"
                wrapperClass="progress-bar-wrapper"
                borderColor = '#111'
                barColor = '#787e96'
            />
        </div>
    }</Layout>
}
