import React from "react";
import {
    getTemplate,
    getUiOptions,
    ArrayFieldTemplateProps,
    ArrayFieldTemplateItemType,
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema, IconButtonProps,
} from "@rjsf/utils";
import {ArrayFieldTitleTemplate} from './ArrayFieldTitle'
import {ArrayFieldDescriptionTemplate} from './ArrayFieldDescription'
import {ArrayFieldItemTemplate} from './ArrayFieldItem'
import {JSONSchema6} from "json-schema";

/** The `ArrayFieldTemplate` component is the template used to render all items in an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export function ArrayFieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: ArrayFieldTemplateProps<T, S, F>) {
    const {
        canAdd,
        className,
        disabled,
        idSchema,
        uiSchema,
        items,
        onAddClick,
        readonly,
        registry,
        required,
        schema,
        title,
    } = props;
    const uiOptions = getUiOptions<T, S, F>(uiSchema);
        const {
        ButtonTemplates: { AddButton },
    } = registry.templates;
    const propal = schema.items as JSONSchema6

    const getTitles = (data: JSONSchema6): string[] => {
        let titles: string[] = []

        for (let key in data.properties) {

            // @ts-ignore
            if (data.properties[key].type === 'object') {
                // @ts-ignore
                titles = titles.concat(getTitles(data.properties[key]).map((title: string): string => key === '__children' ? title : `${key}.${title}`))
                continue
            }

            titles.push(key)
        }

        return titles
    }

    return (
        <fieldset className={className} id={idSchema.$id}>
            {/*<pre>{JSON.stringify(Object.keys(propal.properties as object), null, 2)}</pre>*/}
            <ArrayFieldTitleTemplate
                idSchema={idSchema}
                title={uiOptions.title || title}
                required={required}
                schema={schema}
                uiSchema={uiSchema}
                registry={registry}
            />
            <ArrayFieldDescriptionTemplate
                idSchema={idSchema}
                description={uiOptions.description || schema.description}
                schema={schema}
                uiSchema={uiSchema}
                registry={registry}
            />
            <div className="row array-item-list">
                {/*<div className="col-xs-11 header">*/}
                {/*    {getTitles(propal).map(title => <span key={title}>{title}</span>)}*/}
                {/*</div>*/}
                {items &&
                    items.map(
                        ({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
                            <ArrayFieldItemTemplate key={key} {...itemProps} />
                        )
                    )}
            </div>
            {canAdd && (
                <CustomAddButton
                    className="array-item-add"
                    onClick={onAddClick}
                    disabled={disabled || readonly}
                    uiSchema={uiSchema}
                    registry={registry}

                />
            )}
        </fieldset>
    );
}

function CustomAddButton<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>({ className, onClick, disabled, registry }: IconButtonProps<T, S, F>) {
    return (
        <div className="row">
            <p className={`col-12 col-xs-5 col-xs-offset-7 text-right ${className}`}>
                <IconButton
                    iconType="info"
                    icon="plus"
                    className="btn-add btn-lg col-xs-12"
                    title="Add"
                    onClick={onClick}
                    disabled={disabled}
                    registry={registry}
                />
            </p>
        </div>
    );
}

function IconButton<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: IconButtonProps<T, S, F>) {
    const {
        iconType = "default",
        icon,
        className,
        uiSchema,
        registry,
        ...otherProps
    } = props;
    return (
        <button
            type="button"
            className={`btn btn-${iconType} ${className}`}
            {...otherProps}
        >
            Novi član domaćinstva
        </button>
    );
}
