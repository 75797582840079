import React, { useState, useEffect } from "react";
import { css, styled, setup } from "goober";
import { MultiStepPropsBase, NavButton, Step } from "./interfaces";
import { parseInt } from "lodash";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCheck } from "react-icons/fa";
import { Icon } from "components/Icon";

setup(React.createElement);

const Todo = css`
  &:before {
    content: "\u25CB";
    color: silver;
    background-color: white;
  }
`;
const Doing = css`
  &:before {
    content: "\u2022";
    color: white;
    background-color: #33c3f0;
  }
`;
const Done = css`
  &:before {
    content: "\u2713";
    color: white;
    background-color: #33c3f0;
  }
`;

const RowDirection = css`
  flex-direction: row;
`;

const ColumnDirection = css`
  margin-top: 4.8rem;
  flex-direction: column;
`;

const SwiperSlideItem = css`
  width: 50px;
  height: 50px;
  border-radius: 100%;'

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 1.5rem;

  cursor: pointer;

  background-color: #6bd425;
`;

const SwiperSlideItemTodo = css`
  opacity: 0.2;

  &:hover {
    opacity: 1;
  }
`;

const SwiperSlideItemDone = css`
  background-color: #6bd425;
`;

const getStep = (
  defaultIndex: number,
  newIndex: number,
  length: number
): number => {
  if (newIndex <= length) {
    return newIndex;
  }
  return defaultIndex;
};

const getTopNavStyles = (indx: number, length: number): string[] => {
  const styles: string[] = [];
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push("done");
    } else if (i === indx) {
      styles.push("doing");
    } else {
      styles.push("todo");
    }
  }
  return styles;
};

const getButtonsState = (
  indx: number,
  length: number,
  isValidState: boolean
) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPrevBtn: true,
      showNextBtn: isValidState ? true : false,
    };
  } else if (indx === 0) {
    return {
      showPrevBtn: false,
      showNextBtn: isValidState ? true : false,
    };
  } else {
    return {
      showPrevBtn: true,
      showNextBtn: false,
    };
  }
};

export const MULTISTEP_LOCALSTORAGE_KEY = "multistepLS";
export function MultiStep(props: MultiStepPropsBase) {
  const {
    children,
    keepStepState = false,
    titleStateSetter = (str: string) => console.log("cannot set " + str),
  } = props;

  const [childIsValid, setChildIsValid] = useState(true);

  let lsActiveStep = null;
  if (keepStepState && localStorage.getItem(MULTISTEP_LOCALSTORAGE_KEY)) {
    lsActiveStep = parseInt(
      localStorage.getItem(MULTISTEP_LOCALSTORAGE_KEY) as string
    );
  }

  let stepsArray = props.steps || [];

  if (!stepsArray && !children) {
    throw TypeError("missing children or steps in props");
  }

  const setIsChildInValidState = (isValid: boolean) => setChildIsValid(isValid);

  let steps: Step[] = [];
  if (children) {
    let childrenWithProps = React.Children.map(children, (child, index) => {
      return React.cloneElement(child, {
        signalIfValid: setIsChildInValidState,
      });
    });
    // for backward compatibility we preserve 'steps' with components array:
    steps = childrenWithProps.map((childComponent) => ({
      key: childComponent.props.title,
      title: childComponent.props.title,
      component: childComponent,
    }));
  } else {
    steps = stepsArray;
  }

  const numberOfSteps = steps.length;
  const stepCustomStyle =
    typeof props.stepCustomStyle === "undefined" ? {} : props.stepCustomStyle;
  const showNavButtons =
    typeof props.showNavigation === "undefined" ? true : props.showNavigation;
  const showTitles =
    typeof props.showTitles === "undefined" ? true : props.showTitles;
  const directionType =
    typeof props.direction === "undefined" ? "row" : props.direction;
  const [activeStep, setActiveStep] = useState(
    getStep(0, lsActiveStep || props.activeStep || 0, numberOfSteps)
  );
  const [stylesState, setStyles] = useState(
    getTopNavStyles(activeStep, numberOfSteps)
  );
  const [buttonsState, setButtons] = useState(
    getButtonsState(activeStep, numberOfSteps, childIsValid)
  );

  useEffect(() => {
    setButtons(getButtonsState(activeStep, numberOfSteps, childIsValid));
    // console.log(`From parent, child in valid state?: ${childIsValid}, button state: ${buttonsState.showNextBtn}`)
    titleStateSetter(`Korak ${activeStep + 1}/${numberOfSteps}`);
  }, [activeStep, childIsValid]);

  const setStepState = (indx: number, isValidState: boolean = false) => {
    setStyles(getTopNavStyles(indx, numberOfSteps));
    setActiveStep(indx < numberOfSteps ? indx : activeStep);
    setButtons(getButtonsState(indx, numberOfSteps, isValidState));
  };

  const changeStep = (indx: number) => {
    if (keepStepState) {
      localStorage.setItem(MULTISTEP_LOCALSTORAGE_KEY, indx.toString());
    }
    setStepState(indx);
  };

  const next = () => changeStep(activeStep + 1);
  const previous = () =>
    changeStep(activeStep > 0 ? activeStep - 1 : activeStep);

  // const handleOnClick = (evt: { currentTarget: { value: number } }) => {
  const handleOnClick = (stepNumber: number) => {
    if (!childIsValid) {
      console.info("Child not in valid state - no transition");
      return;
    }

    if (stepNumber === numberOfSteps - 1 && activeStep === numberOfSteps - 1) {
      setStepState(numberOfSteps);
    } else {
      setStepState(stepNumber);
    }
  };

  const renderTopNav = () =>
    steps.map((s, i) => {
      const isTodo = stylesState[i] === "todo";
      const isDoing = stylesState[i] === "doing";

      let classNames = `${SwiperSlideItem}`;

      if (isTodo) {
        classNames += ` ${SwiperSlideItemTodo}`;
      }

      if (!isTodo && !isDoing) {
        classNames += ` ${SwiperSlideItemDone}`;
      }

      return (
        <SwiperSlide style={{width: '50px'}}>
          <div className={classNames} onClick={() => handleOnClick(i)}>
            {isTodo || isDoing ? (
              <span>{i + 1}</span>
            ) : (
              <Icon>
                <FaCheck />
              </Icon>
            )}
          </div>
        </SwiperSlide>
      );
    });

  const renderButtonsNav = (
    show: boolean,
    prevButton?: NavButton,
    nextButton?: NavButton
  ) =>
    show && (
      <div>
        <button
          onClick={previous}
          className={"btn btn-lg btn-info btn-nav btn-prev"}
          style={prevButton?.style}
          disabled={buttonsState.showPrevBtn ? false : true}
        >
          {prevButton && prevButton.title ? <>{prevButton.title}</> : <>Prev</>}
        </button>
        <button
          onClick={next}
          className={"btn btn-lg btn-info btn-nav btn-next"}
          style={nextButton?.style}
          disabled={buttonsState.showNextBtn ? false : true}
        >
          {nextButton && nextButton.title ? <>{nextButton.title}</> : <>Next</>}
        </button>
      </div>
    );

  console.log({ steps });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: directionType === "column" ? "row" : "column",
      }}
    >
      {/* <Ol
        className={directionType === "column" ? ColumnDirection : RowDirection}
      >
        {renderTopNav()}
      </Ol> */}
      <div className="mb-6">
        <Swiper spaceBetween={30} slidesPerView="auto" slidesPerGroup={3}>
          {renderTopNav()}
        </Swiper>
      </div>
      {steps[activeStep].component}
      <div>
        {renderButtonsNav(showNavButtons, props.prevButton, props.nextButton)}
      </div>
    </div>
  );
}
