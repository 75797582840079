import { Column, Columns } from "components/Bulma";
import { useAuth } from "hooks/auth";
import { FaBars, FaSignOutAlt } from "react-icons/fa";

export default function AdminTopBar({ sidebar, setSidebar }: any) {
  const { logout } = useAuth();

  return (
    <div className="top-bar">
      <Columns mobile vcentered>
        <Column>
          <button
            style={{ cursor: "pointer", padding: 10 }}
            onClick={() => setSidebar(!sidebar)}
          >
            <FaBars />
          </button>
        </Column>
        <Column className="has-text-right">
          <button style={{ cursor: "pointer", padding: 10 }} onClick={logout}>
            <FaSignOutAlt />
          </button>
        </Column>
      </Columns>
    </div>
  );
}
