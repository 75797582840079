import Layout from "components/Layout";
import { useAuth } from "hooks/auth";
import { Navigate } from "react-router-dom";

export default function DashboardPage() {
  const { localUser } = useAuth();

  if (!localUser) {
    return <Navigate to={`/login`} replace />;
  }

  return (
    <Layout>
      <h1>In progress</h1>
    </Layout>
  );
}
