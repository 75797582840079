import React, {useState, useRef} from 'react';
import Autosuggest, {
    ChangeEvent,
    InputProps,
    RenderSuggestionParams, RenderSuggestionsContainerParams,
    SuggestionSelectedEventData
} from 'react-autosuggest';
import {getTemplate, WidgetProps} from "@rjsf/utils";

interface Suggestion {
    label: string
    value: string
}

export const config = {
    css: {
        container: 'list-group',
        item: 'list-group-item list-group-item-action',
        activeItem: 'list-group-item list-group-item-action active',
        input: 'form-control',
    },
}

class TSuggestion {
    private _label: string = ''
    private _value: string = ''

    get label(): string {
        return this._label;
    }
    set label(value: string) {
        this._label = value;
    }

    get value(): string {
        return this._value;
    }
    set value(value: string) {
        this._value = value;
    }

    constructor(params: Suggestion | string) {
        this.value = typeof params === 'string' ? params : params.value
        this.label = typeof params === 'string' ? params : params.label
    }
}

export const Autosuggess = (props: WidgetProps) => {
    const {options, value = ''} = props
    const choices = options.choices as string[]

    const tsSuggestions: TSuggestion[] = choices.map(
        suggestion => new TSuggestion(suggestion)
    )

    const [valueState, setValue] = useState<string>(value)
    const [suggestionsState, setSuggestions] = useState<TSuggestion[]>(tsSuggestions)

    const renderSuggestionsContainer = ({ containerProps, children }: RenderSuggestionsContainerParams) => {
        containerProps.className = 'list-group'
        return <div {...containerProps}>{children}</div>
    }

    const onSuggestionsFetchRequested = ({ value = '' }) => setSuggestions(
        tsSuggestions.filter(suggestion => suggestion.value.toLowerCase().includes(value.toLowerCase()))
    )

    const onSuggestionsClearRequested = () => setSuggestions([])

    const onSuggestionSelected = (
        event: React.FormEvent<HTMLElement>, { suggestion }: SuggestionSelectedEventData<TSuggestion>
    ) => {
        props.onChange(suggestion.value)
        setValue(suggestion.value)
    }

    const getSuggestionValue = (suggestion: TSuggestion): string => suggestion.value

    const renderSuggestion = (
        suggestion: TSuggestion, { query, isHighlighted }: RenderSuggestionParams
    ) => (
        <div className={'list-group-item' + (isHighlighted ? ' list-group-item-action active' : '')}>
            {suggestion.label}
        </div>
    )

    const onChange = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
        props.onChange(newValue)
        setValue(newValue);
    };

    const inputProps: InputProps<TSuggestion> = {
        placeholder: 'Type something',
        value: valueState,
        onChange,
        className: 'form-control',
        id: props.id,
        name: props.id
    };


    return <Autosuggest
        suggestions={suggestionsState}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        {...props}
    />
}
