import React from "react";
import {
    FieldTemplateProps,
    FormContextType,
    RJSFSchema,
    StrictRJSFSchema,
    getTemplate,
    getUiOptions,
} from "@rjsf/utils";

import {Label} from "./Label";

/** The `FieldTemplate` component is the template used by `SchemaField` to render any field. It renders the field
 * content, (label, description, children, errors and help) inside of a `WrapIfAdditional` component.
 *
 * @param props - The `FieldTemplateProps` for this component
 */
export function FieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: FieldTemplateProps<T, S, F>) {
    const {
        id,
        label,
        children,
        errors,
        help,
        description,
        hidden,
        required,
        displayLabel,
        registry,
        uiSchema,
    } = props;
    // const displayLabel = false
    const uiOptions = getUiOptions(uiSchema);
    const WrapIfAdditionalTemplate = getTemplate<
        "WrapIfAdditionalTemplate",
        T,
        S,
        F
    >("WrapIfAdditionalTemplate", registry, uiOptions);
    if (hidden) {
        return <div className="hidden">{children}</div>;
    }
    return (
        <WrapIfAdditionalTemplate {...props}>
            {displayLabel && <Label label={label} required={required} id={id} />}
            {displayLabel && description ? description : null}
            {children}
            {errors}
            {help}
        </WrapIfAdditionalTemplate>
    );
}
