import { useAuth } from "../hooks/auth";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import axios from "lib/axios";
import { AxiosError, AxiosResponse } from "axios";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridSortItem,
  GridSortModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Button } from "./Bulma";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";
import { GridFilterModel } from "@mui/x-data-grid/models/gridFilterModel";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { ProgressBar } from "react-loader-spinner";
import { Icon } from "./Icon";
import { FaPlus, FaEdit, FaRegTrashAlt } from "react-icons/fa";

interface Links {
  first?: string;
  last?: string;
  prev?: string;
  next?: string;
}

interface Meta {
  path?: string;
  current_page?: number;
  from?: number;
  last_page?: number;
  per_page?: number;
  to?: number;
  total?: number;
  links?: {
    url: string;
    label: string;
    active: boolean;
  }[];
}

export interface Resp<T = Record<string, any>> {
  data: T[];
  links: Links;
  meta: Meta;
}

const flattenObject = (
  obj: Record<string, any>,
  prefix = ""
): Record<string, any> =>
  Object.keys(obj).reduce((acc: Record<string, any>, k: string) => {
    const pre = prefix.length ? prefix + "." : "";

    return typeof obj[k] === "object" && obj[k] !== null
      ? { ...acc, ...flattenObject(obj[k], pre + k) }
      : { ...acc, [pre + k]: obj[k] };
  }, {});

export class Items<T = Record<string, any>> {
  private _data: T[];
  private _links: Links;
  private _meta: Meta;

  constructor(
    data: Resp = { data: [], links: {}, meta: {} },
    readonly filled: boolean = false
  ) {
    this._data = data.data.map((item) => flattenObject(item) as T);
    this._links = data.links;
    this._meta = data.meta;
  }

  get links(): Links {
    return this._links;
  }

  set links(value: Links) {
    this._links = value;
  }

  get data(): T[] {
    return this._data;
  }

  set data(value: T[]) {
    this._data = value;
  }
  get meta(): Meta {
    return this._meta;
  }

  set meta(value: Meta) {
    this._meta = value;
  }
}

export const DefaultListing = (itemAttrs: string[], ...urlParts: string[]) => {
  const { pathname } = useLocation();
  const nav = useNavigate();
  const { localUser, logout } = useAuth();
  const [items, setItems] = useState(new Items());
  const [gridPaginationModel, setGridPaginationModel] = useState<
    GridPaginationModel | undefined
  >(undefined);
  const [gridSortModel, setGridSortModel] = useState<GridSortModel | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [itemAttrsLabelMap, setItemattrsLabelMap] = useState<
    Record<string, string>
  >(
    itemAttrs.reduce((cumulated: Record<string, any>, value) => {
      cumulated[value] = null;
      return cumulated;
    }, {})
  );

  const cols: GridColDef[] = itemAttrs.map((attr) => ({
    field: attr,
    renderHeader: (params) => itemAttrsLabelMap[params.field] || params.field,
    flex: 1,
    minWidth: attr === 'id' ? 80 : 200,
  }));
  cols.push({
    field: "options",
    renderHeader: (params) => itemAttrsLabelMap[params.field] || params.field,
    flex: 1,
    minWidth: 150,
    sortable: false,
    editable: false,
    hideable: false,
    filterable: false,
    renderCell: (params) => {
      //mui.com/material-ui/react-table/#data-table
      https: return (
        <>
          <Button
            variant="white"
            onClick={() => nav(`${pathname}/${params.row.id}`)}
          >
            <Icon size="1.2em">
              <FaEdit />
            </Icon>
          </Button>
          <Button variant="danger" onClick={deleteItem(params.row.id)}>
            <Icon size="1.2em">
              <FaRegTrashAlt />
            </Icon>
          </Button>
        </>
      );
    },
  });

  const init = async () => {
    const resp = await axios.get("translations");
    setItemattrsLabelMap(resp.data);

    try {
      if (items.filled) {
        return;
      }

      const res: { data: Resp } = await axios.get(
        `${urlParts.join("/")}?select=${itemAttrs.join(",")}`
      );
      setItems(new Items(res.data, true));

      // debugger
      if (res.data.meta.current_page && res.data.meta.per_page) {
        setGridPaginationModel({
          page: res.data.meta.current_page - 1,
          pageSize: res.data.meta.per_page,
        });
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        const { status, data } = e.response as AxiosResponse<{
          message: string;
        }>;

        if (status === 401) {
          logout();
          return <Navigate to={`/login`} replace />;
        }

        if (status === 403) {
          alert(data.message);
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!items.filled) {
      init();
    }
  }, [items]);

  if (!localUser) {
    return <Navigate to={`/login`} replace />;
  }

  const deleteItem = (id: number) => async () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }
    await axios.delete(`${urlParts.join("/")}/${id}`);
    setItems(new Items());
  };

  const handlePagination = (data: GridPaginationModel) => {
    setLoading(true);
    let params: Record<string, any> = {
      select: itemAttrs.join(","),
      page: data.page + 1,
      per_page: data.pageSize,
    };

    if (gridSortModel?.length) {
      const sortItem: GridSortItem = gridSortModel[0];
      params = {
        ...params,
        sortBy: sortItem.field,
        sortDirection: sortItem.sort,
      };
    }

    const query = Object.keys(params)
      .map(
        (key: string) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");

    axios.get(`${items.meta.path}?${query}`).then((res) => {
      setItems(new Items(res.data, true));
      setGridPaginationModel({
        page: res.data.meta.current_page - 1,
        pageSize: res.data.meta.per_page,
      });
      setLoading(false);
    });
  };

  const handleSort = (data: GridSortModel) => {
    setLoading(true);
    let params: Record<string, any> = {
      select: itemAttrs.join(","),
    };

    if (gridPaginationModel) {
      params = {
        ...params,
        page: gridPaginationModel.page + 1,
        per_page: gridPaginationModel.pageSize,
      };
    }

    const sortItem: GridSortItem = data[0];

    if (sortItem) {
      params = {
        ...params,
        sortBy: sortItem.field,
        sortDirection: sortItem.sort,
      };
    }

    const query = Object.keys(params)
      .map(
        (key: string) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");

    axios.get(`${items.meta.path}?${query}`).then((res) => {
      setItems(new Items(res.data, true));
      setGridSortModel(data);
      setLoading(false);
    });
  };

  const handleFilterModelChange = (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => {
    debugger;
  };

  return (
    <Layout>
      <Button
        onClick={() => nav(`${pathname}/create`)}
        variant="success"
        className="mb-5"
      >
        <Icon size="1.25em" style={{ marginRight: "10px" }}>
          <FaPlus />
        </Icon>{" "}
        Dodaj
      </Button>

      {items.filled ? (
        <DataGrid
          rows={items.data}
          columns={cols}
          autoHeight
          onPaginationModelChange={handlePagination}
          paginationMode="server"
          onSortModelChange={handleSort}
          sortingMode="server"
          // onResize={(params, event, details) => {
          //     debugger
          // }}
          onFilterModelChange={handleFilterModelChange}
          paginationModel={gridPaginationModel}
          sortModel={gridSortModel}
          rowCount={items.meta.total}
          pageSizeOptions={[5, 10, 15, 30, 50, 100]}
          loading={loading}
        />
      ) : (
        <ProgressBar
          height="80"
          width="100%"
          ariaLabel="progress-bar-loading"
          wrapperClass="progress-bar-wrapper"
          borderColor="#111"
          barColor="#787e96"
        />
      )}
    </Layout>
  );
};
