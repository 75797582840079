import { FC } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ReactPaginate from "react-paginate";

export interface PaginationProps {
  currentPage: number;
  lastPage: number;
  onChange: (selectedItems: { selected: number }) => void;
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  lastPage,
  onChange,
}) => {
  if (lastPage < 2) return <></>;

  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <ReactPaginate
        containerClassName={`pagination-list`}
        pageLinkClassName={`pagination-link`}
        activeLinkClassName={`is-current`}
        previousLinkClassName={`pagination-link`}
        nextLinkClassName={`pagination-link`}
        breakLinkClassName={`pagination-link`}
        previousLabel={<FiChevronLeft />}
        nextLabel={<FiChevronRight />}
        forcePage={currentPage}
        pageCount={lastPage}
        onPageChange={onChange}
        disableInitialCallback={true}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
      />
    </nav>
  );
};
