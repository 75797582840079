import { CSSProperties, FC, PropsWithChildren } from "react";
import { IconContext } from "react-icons/lib";

interface IconProps {
  color?: string;
  size?: string;
  className?: string;
  style?: CSSProperties;
}

export const Icon: FC<PropsWithChildren<IconProps>> = ({
  color = "inherit",
  size = "1em",
  className = "",
  style,
  children,
}) => {
  return (
    <IconContext.Provider
      value={{
        color,
        size,
        className,
        style: { ...style, verticalAlign: "middle" },
      }}
    >
      {children}
    </IconContext.Provider>
  );
};
