// import Layout from "../components/Layout";
// import {useAuth} from "../hooks/auth";
// import {useState} from "react";
// import axios from "../lib/axios";
// import {AxiosError, AxiosResponse} from "axios";
// import {Table} from "../components/Layout/Table";
// import {Items, Resp} from "../components/DefaultListing";
// import Form, {IChangeEvent} from "@rjsf/core";
// import {JSONSchema7} from "json-schema";
// import {ErrorSchema, RJSFSchema, UiSchema} from "@rjsf/utils";
// import validator from "@rjsf/validator-ajv8";
// import {useParams, useNavigate, useLocation} from "react-router-dom";
// import {diff} from "deep-object-diff";
//
// interface PermissionI {
//     id: number
//     name: string
//     action_name: string
//     created_at: string
//     updated_at: string
// }
//
// type PermissionAttr = 'name'|'action_name'
//
// interface Schema extends JSONSchema7{
//     properties: Record<PermissionAttr, JSONSchema7>
// }
//
// const PermissionSchema: Schema = {
//     type: 'object',
//     properties: {
//         name: {
//             type: 'string',
//         },
//         action_name: {
//             type: 'string',
//         },
//     },
// }
//
// const PermissionUiSchema: Record<string, UiSchema> = {}
//
// const PermissionListing = () => {
//     const {localUser, logout} = useAuth()
//     const [permissions, setPermissions] = useState<Items>(new Items<PermissionI>())
//
//     const init = () => {
//         axios.get('permissions')
//             .then((res: AxiosResponse<Resp<PermissionI>>) => setPermissions(new Items<PermissionI>(res.data, true)))
//             .catch((error) => {
//                 if (error.response.status === 403) {
//                     alert(error.response.data.message)
//                     setPermissions(new Items({data: [], links: {}}, true))
//                 }
//                 // if (error.response.status !== 401) {
//                 //     return logout()
//                 // }
//             })
//     }
//
//     if (!permissions.filled) {
//         init()
//     }
//
//
//     if (!localUser) {
//         logout()
//     }
//
//     const handlePagination = () => {}
//     const handleDelete = (id: number) => async () => {
//         if (!window.confirm("Are you sure?")) {
//             return;
//         }
//         await axios.delete(`permissions/${id}`)
//         setPermissions(new Items())
//     }
//
//     return (
//         <Layout>
//             <Table
//                 itemAttrs={['id', 'name', 'action_name']}
//                 items={permissions}
//                 handlePagination={handlePagination}
//                 deleteItem={handleDelete}
//             />
//         </Layout>
//     )
// }
//
// enum Progress {
//     init, progressing, done
// }
//
// interface Pipeline {
//     call: () => Promise<AxiosResponse>
//     callback: (res: AxiosResponse) => void
// }
//
// const PermissionEdit = () => {
//     const navigate = useNavigate()
//     const location = useLocation()
//     const {id} = useParams();
//     const {logout} = useAuth();
//     const [permission, setPermission] = useState<PermissionI|{}>({})
//     const [schema, setSchema] = useState<RJSFSchema>(PermissionSchema)
//     const [progress, setProgress] = useState<Progress>(Progress.init)
//
//     const pipeline: Pipeline[] = []
//
//     if (id !== 'create') {
//         pipeline.push({
//             call: () => axios.get(`permissions/${id}`),
//             callback: (res: AxiosResponse<PermissionI>) => setPermission(res.data)
//         })
//     }
//
//     if (progress === Progress.init){
//         setProgress(Progress.progressing)
//         Promise.all(pipeline.map(p => p.call())).then(responses => {
//             responses.forEach(
//                 (response, key) => pipeline[key].callback(response)
//             )
//
//             setProgress(Progress.done)
//         })
//     }
//
//     const handleSubmit = (e: IChangeEvent) => {
//         const catchCallback = (e: AxiosError<{errors: ErrorSchema}>) => {
//             // setExtraErrors(e.response?.data.errors)
//         }
//
//         if (id === 'create') {
//             if (JSON.stringify(e.formData) === '{}') {
//                 return;
//             }
//             return axios.post(
//                 'permissions', e.formData
//             ).then((res: AxiosResponse<PermissionI>) => {
//                 setPermission(res.data)
//
//                 alert('success')
//                 navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/${res.data.id}`)
//             }).catch(catchCallback)
//         }
//
//         if (
//             [permission, e.formData].includes(undefined)
//             && JSON.stringify(diff(permission, e.formData)) === '{}'
//         ) {
//             return;
//         }
//
//         axios.patch(
//             `permissions/${id}`, diff(permission, e.formData)
//         ).then((response: AxiosResponse<PermissionI>) => {
//             setPermission(response.data)
//             alert('success')
//         }).catch(catchCallback)
//     }
//
//     return progress === Progress.done ? (
//         <Layout>
//             <Form
//                 schema={schema}
//                 uiSchema={PermissionUiSchema}
//                 formData={permission}
//                 validator={validator}
//                 onSubmit={handleSubmit}
//             />
//         </Layout>
//     ): (<Layout>
//         loading
//     </Layout>)
// }
//
// export {
//     PermissionListing,
//     PermissionEdit,
// }

import { DefaultListing } from "../components/DefaultListing"
import {DefaultEdit} from "../components/DefaultEdit";

export const PermissionListing = () => DefaultListing(['id', 'name', 'action_name', 'created_at', 'updated_at'], 'permissions')

export const PermissionEdit = () => DefaultEdit({endpoint: 'permissions'})
