import React from "react";
import {
    FormContextType,
    ObjectFieldTemplatePropertyType,
    ObjectFieldTemplateProps,
    RJSFSchema,
    StrictRJSFSchema,
    canExpand,
    descriptionId,
    getTemplate,
    getUiOptions,
    titleId,
} from "@rjsf/utils";

/** The `ObjectFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 *
 * @param props - The `ObjectFieldTemplateProps` for this component
 */
export function ObjectFieldTemplate<
    T = any,
    S extends StrictRJSFSchema = RJSFSchema,
    F extends FormContextType = any
>(props: ObjectFieldTemplateProps<T, S, F>) {
    const {
        description,
        disabled,
        formData,
        idSchema,
        onAddClick,
        properties,
        readonly,
        registry,
        required,
        schema,
        title,
        uiSchema,
    } = props;
    const options = getUiOptions<T, S, F>(uiSchema);
    const TitleFieldTemplate = getTemplate<"TitleFieldTemplate", T, S, F>(
        "TitleFieldTemplate",
        registry,
        options
    );
    const DescriptionFieldTemplate = getTemplate<
        "DescriptionFieldTemplate",
        T,
        S,
        F
    >("DescriptionFieldTemplate", registry, options);
    // Button templates are not overridden in the uiSchema
    const {
        ButtonTemplates: { AddButton },
    } = registry.templates;
    return (
        <fieldset id={idSchema.$id} className={'row'}>
            {/*{(options.title || title) && (*/}
            {/*    <TitleFieldTemplate*/}
            {/*        id={titleId<T>(idSchema)}*/}
            {/*        title={options.title || title}*/}
            {/*        required={required}*/}
            {/*        schema={schema}*/}
            {/*        uiSchema={uiSchema}*/}
            {/*        registry={registry}*/}
            {/*    />*/}
            {/*)}*/}
            {(options.description || description) && (
                <DescriptionFieldTemplate
                    id={descriptionId<T>(idSchema)}
                    description={options.description || description!}
                    schema={schema}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
            {properties.map((prop: ObjectFieldTemplatePropertyType) => prop.content)}
            {canExpand<T, S, F>(schema, uiSchema, formData) && (
                <AddButton
                    className="object-property-expand"
                    onClick={onAddClick(schema)}
                    disabled={disabled || readonly}
                    uiSchema={uiSchema}
                    registry={registry}
                />
            )}
        </fieldset>
    );
}
